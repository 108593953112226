<template>
    <div class="components">
        <div class="head height-100">
            <div class="head-logo ">
                <img :src="imgObj.homeImg" width="50px" height="50px">
                <div class="head-logo-name">
                    <div class="appName">旺街邮</div>
                    <div class="appData">发货找车的物流货运平台</div>
                </div>
            </div>

            <div class="theme">
                <div class="themeItem" v-for="item in themeList " :key="item.id">
                    <div @click="onButtonClick( item.type)"
                         class="buttonCursor marginRight-110 flex-column flexAlignItems ">
                        <div v-if="item.type===showTheme">
                            <div class="text16_c3">{{item.name}}</div>
                            <div class="headLin"></div>
                        </div>
                        <div v-else>
                            <div class="text16_33 pitchOn">{{item.name}}</div>
                            <div class="whiteheadLin "></div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div >
                <div class="head-img img_com">
                    <div class="white_125 marginTop-80">发货就用旺街邮</div>
                    <div class="white_125">自邮更自由</div>

                </div>
                <div  class="headline_data">
                    <div id="dynamic" class=" headlineNews headline">
                        <div>最新动态</div>
                        <img :src="imgObj.comma">
                    </div>
                </div>
                <div class="flexAlignItems">
                    <div class="box1">
                        <img @mouseover="leftTransToWhite()"
                             @mouseleave="leftTransToBlack()"
                             class="arrow buttonCursor" :src="left_arrow" @click="onLeft()">
                        <ul>
                            <li class="buttonCursor" v-for="item in dataList " :key="item.id"
                                @click="onDynamicClick(item.id)">

                                <div class="flex-column">
                                    <div class="text24c33 pitchOn fontBold">{{item.title}}</div>
                                    <div class="text16_66">{{item.createTime}}</div>

                                </div>
                                <img class="stable_img" :src="item.image">
                            </li>

                        </ul>
                        <img @mouseover="transToWhite()"
                             @mouseleave="transToBlack()"
                             class="arrow buttonCursor" :src="right_arrow" @click="onRight()">
                    </div>

                </div>
                <div   class="flexAlignItems">
                    <div  class="aboutUsCom">
                        <div class="about-us-view">
                            <div id="about"  class="headline">
                                <div>关于我们</div>
                                <img :src="imgObj.comma">
                            </div>
                            <div class="about-us-data">&emsp;&emsp;旺街邮是一家互联网物流服务平台。通过共享模式整合社会运力资源，完成海量运力储备，依托移动互联、大数据和人工智能技术，搭建“便捷、平价、安全、专业”的货运平台，实现多种车型的即时智能调度，为个人、商户及企业提供高效的物流解决方案。全平台月活司机48万，月活用户达600万。</div>
                            <div class="directionRow ">
                                <div>
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">1000</div>
                                        <div class="text30">+</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">服务企业总数</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>
                                <div class="marginLeft-80">
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">600</div>
                                        <div class="text30">+</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">服务大中型企业</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>
                                <div class="marginLeft-80">
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">100w</div>
                                        <div class="text30">+</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">服务用户总数</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>
                            </div>
                        </div>
                        <img class="aboutUsComImg" :src="imgObj.about_us_item">
                    </div>
                </div>
                <div class="bossDelivery img_com">
                    <div class="orderView">
                        <div id="shipments" class="driver-orders">
                            <div class="headline newMargin">
                                <div>老板发货找车</div>
                                <img :src="imgObj.comma">
                            </div>
                            <div class="directionRow ">
                                <div>
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">发货更省钱</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">智能建议价&emsp;自由喊价</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>
                                <div class="marginLeft-80">
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">直达不中转</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">在线下单&emsp;省时省力</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div class="driverOrderView">
                        <div id="order" class="driverOrders">
                            <div class="headline newMargin">
                                <div>司机接单拉货</div>
                                <img :src="imgObj.comma">
                            </div>
                            <div class="directionRow ">
                                <div>
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">货源多/结单快</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">平台每天上万订单</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>
                                <div class="marginLeft-80">
                                    <div class="directionRow alignItems marginTop-80">
                                        <div class="text40">无需路边趴活</div>
                                    </div>
                                    <div class="text16_66 marginTop-20">在线接单不用等</div>
                                    <div class="lin18 marginTop-20"></div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div id="relation" class="contactUs img_com">
                    <div class="contactUsHeadline">
                        <div class=" headlineNews headline">
                            <div>联系我们</div>
                            <img :src="imgObj.comma">
                        </div>
                    </div>
                    <div class="contact-us-view">

                        <div class="text24-22">杭州旺街网络科技有限公司</div>
                        <div class="addressPhone-view addressView marginTop-20">
                            <div class="addressPhone " >
                                <img :src="imgObj.address_icon">
                                <div class="text18-55">杭州市萧山区农业大厦1号楼25层</div>
                            </div>
                            <div class="addressPhone ">
                                <img :src="imgObj.phone_icon">
                                <div class="text18-55">0571-82136687</div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div>
                <div class="buttonLin"></div>
                <div class="button">
                    <div class="flex-column marginTop-40">
                        <img class="logoImg" :src="imgObj.logoMin">
                        <div class="companyName">杭州旺街网络科技有限公司</div>
                        <div class="companyAddress">Copyright © 2018 杭州旺街网络科技 版权所有
                        </div>
                        <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备18030766号-1</a>
                        <div class="flexDirectionRow companyAddress">
                            <img class="badge" :src="imgObj.badge">
                            <a class="marginLeft-10"
                               href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002583"
                               target="_blank">浙公网安备 33010902002583号</a>
                        </div>
                    </div>
                    <div class="buttonLin-height marginTop-40 marginLeft-80"></div>
                    <div class="flex-column marginTop-40 marginLeft-80">
                        <div class="text14c33">电话：0571-82136687</div>
                        <div class="text14c33">客服微信：wonstreet</div>
                        <div class="text14c33">邮箱：wonstreet@126.com</div>
                        <div class="text14c33">地址：杭州市萧山区农业大厦1号楼25层</div>
                    </div>

                    <div class="flexDirectionRow marginLeftAuto">
                        <div class="buttonLin-height marginTop-10 marginRight-50 "></div>
                        <div class="flex-column flexAlignItems marginRight-50">
                            <img class="codeSize" :src="imgObj.downloadCode">
                            <div class="text14c33">扫描下载旺街邮APP</div>
                        </div>
                        <div class="flex-column flexAlignItems ">
                            <img class="codeSize" :src="imgObj.publicCode">
                            <div class="text14c33 ">扫描关注旺街邮公众号</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import homeImg from '@/assets/img/logo.png'
    import logoMin from '@/views/img/logo-min.png'
    import badge from '@/views/img/badge.png'
    import downloadCode from '@/views/img/download_code.png'
    import publicCode from '@/views/img/public_code.png'
    import right_arrow_off from '@/views/img/right_arrow_off.png'
    import right_arrow_on from '@/views/img/right_arrow_on.png'
    import left_arrow_off from '@/views/img/left_arrow_off.png'
    import left_arrow_on from '@/views/img/left_arrow_on.png'
    import comma from '@/views/img/comma.png'
    import about_us_item from '@/views/img/about_us_item.png'
    import address_icon from '@/views/img/address-icon.png'
    import phone_icon from '@/views/img/phone-icon.png'
    import axios from "axios";

    export default {
        data() {
            return {
                slide: 0,
                sliding: null,
                imgObj: {
                    homeImg, logoMin, badge, publicCode,
                    downloadCode, right_arrow_off, right_arrow_on,
                    left_arrow_off, left_arrow_on, comma, about_us_item,
                    address_icon, phone_icon
                },
                showTheme: "",
                right_arrow: right_arrow_off,
                left_arrow: left_arrow_off,
                type: this.$route.query.type,
                HTTP: null,
                pageNumber: 1,
                dataList: [],
                existNextPage: 0,
                themeList: [
                    {
                        type: "dynamic",
                        name: "最新动态",
                        id: 1,
                    },
                    {
                        type: "about",
                        name: "关于我们",
                        id: 2,
                    },
                    {
                        type: "shipments",
                        name: "老板发货",
                        id: 3,
                    },
                    {
                        type: "order",
                        name: "司机接单",
                        id: 4,
                    },
                    {
                        type: "relation",
                        name: "联系我们",
                        id: 5,
                    },
                ]
            }
        },
        methods: {
            onSlideStart() {
                this.sliding = true
            },
            onSlideEnd() {
                this.sliding = false
            },
            //右加
            onRight() {
                if (this.existNextPage === 1) {
                    this.pageNumber = this.pageNumber + 1
                    this.init()
                }

            },
            //左加
            onLeft() {
                if (this.pageNumber > 1) {
                    this.pageNumber = this.pageNumber - 1
                }
                this.init()
            },
            onButtonClick: function (e) {
                this.showTheme = e;
                document.getElementById(e).scrollIntoView();
            },
            onDynamicClick: function (id) {
                // this.$router.push({
                //     path: '/news', query: {
                //         id: id,
                //     }
                // })
                let detailId=id+".html"
                let routeUrl = this.$router.resolve({
                    name:"News",
                    params:{id:detailId}
                });
                window.open(routeUrl.href, '_blank');

            },
            showInit() {
                if (this.type) {
                    this.showTheme = this.type;
                }
                this.onButtonClick(this.showTheme)
            }
            ,
            init() {
                this.HTTP = axios.create({
                    baseURL: 'https://yourestpro.wonstreet.com',
                    timeout: 1000,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    params: {
                        pageNumber: this.pageNumber,
                        pageSize: 4,
                    }
                });


                this.HTTP.post('/br/information/listWithPage')
                    .then((res) => {

                        this.dataList = res.data.result.list
                        this.existNextPage = res.data.result.existNextPage

                    })

            },
            //鼠标移入触发
            transToWhite: function () {
                this.right_arrow = this.imgObj.right_arrow_on;
            },
            //鼠标移出触发
            transToBlack: function () {
                this.right_arrow = this.imgObj.right_arrow_off;

            },
            leftTransToWhite: function () {   //鼠标移入触发
                this.left_arrow = this.imgObj.left_arrow_on;
            },
            leftTransToBlack: function () { //鼠标移出触发
                this.left_arrow = this.imgObj.left_arrow_off;

            }

        },
        /**
         * 数据加载完成后
         */
        mounted() {

        },
        created() {
            this.init()

        },
    }
</script>
<style>
    html, body {
        height: 100%;
        margin: 0;
    }
    .components {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        /*布局方向是垂直的*/
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .flexDirectionRow{
        display: flex;
        flex-direction: row;
    }
    .head {
        display: flex;
        flex-direction: row;
        box-shadow: 0px 12px 40px 0px
        rgba(229, 229, 229, 0.42);
        text-align: center;
    }

    .main {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 100%;
        overflow: auto;
    }

    .height-100 {
        height: 100px
    }

    .theme {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: 100px;

    }

    .themeItem {
        display: flex;
        flex-direction: row;
        margin-left: auto;
    }

    .head-logo {
        display: flex;
        flex-direction: row;
        margin-left: 160px;
        align-items: center;
    }

    .head-logo-name {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

    }

    .appName {
        font-size: 24px;
        margin-left: 20px;
        color: #333333;
    }

    .appData {
        font-size: 18px;
        color: #666666;
        margin-left: 20px;
    }

    .head-img {
        height: 800px;
        background-image: url('../img/zhichou-banner-com.png');

    }

    .img_com {
        width: auto;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .headline_data {
        width: auto;
        height: 223px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .headlineNews {
        display: flex;
        flex-direction: row;
        width: 1600px;
        justify-content: flex-end;
    }

    .headline {
        display: flex;
        flex-direction: row;
    }

    .headline div {
        color: #333333;
        font-size: 48px;
        font-weight: bold;

    }

    .headline img {
        width: 32px;
        height: 32px;
        margin-left: 15px;
    }

    .aboutUsCom {
        display: flex;
        flex-direction: row;
        width: 1600px;
        height: 520px;
        margin-top: 110px;

    }

    .aboutUsComImg {
        flex-grow: 1;
    }

    .bossDelivery {
        height: 1664px;
        background: url('../img/order_img.png');
        display: flex;
        flex-direction: column;
    }

    .driverOrders {

    }

    .contactUs {
        height: 921px;
        background: url('../img/contact_us_com.png');
    }

    .contactUsHeadline {
        width: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .contact-us-view {
        margin-top: 370px;
        width: 988px;
        height: 204px;
        background-color: #ffffff;
        box-shadow: 0px 12px 40px 0px rgba(229, 229, 229, 0.42);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 94px;
        padding-right: 94px;

    }

    .buttonLin {
        width: auto;
        height: 4px;
        background-color: #00c395;
        margin-top: 100px;
    }

    .button {
        padding-top: 10px;
        padding-bottom: 60px;
        width: 1600px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    .flex-column {
        display: flex;
        flex-direction: column;
    }

    .pitchOn:hover {
        color: #00c395;

    }

    .buttonCursor {
        cursor: pointer;
    }

    .flexAlignItems {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .marginLeftAuto {
        margin-left: auto;
        padding-top: 30px;


    }

    .logoImg {
        width: 50px;
        height: 50px;
    }

    .badge {
        width: 20px;
        height: 20px;
    }

    .companyName {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 18px;
        color: #333333;
    }

    .companyAddress {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        font-size: 14px;
        color: #999999;
    }

    .codeSize {
        width: 160px;
        height: 160px;
    }

    .marginTop-40 {
        margin-top: 40px;
    }

    .marginTop-80 {
        margin-top: 80px;
    }

    .marginLeft-80 {
        margin-left: 80px;
    }

    .marginRight-50 {
        margin-right: 50px;

    }

    .marginRight-110 {
        margin-right: 110px;

    }

    .marginTop-10 {
        margin-top: 10px;
    }

    .marginTop-20 {
        margin-top: 20px;
    }

    .buttonLin-height {
        width: 1px;
        height: 140px;
        background-color: #e8e8e8;
    }

    .text14c33 {
        font-size: 14px;
        margin-top: 10px;
        color: #333333;
        display: flex;
        flex-direction: row;
    }

    .text24c33 {
        color: #333333;
        display: flex;
        margin-right: 10px;
        flex: 1;
        flex-direction: column;
        font-size: 24px;
        align-items: flex-start;
        justify-content: flex-start;


    }

    .headLin {
        width: 24px;
        height: 2px;
        background-color: #00c395;
        border-radius: 1px;
        margin-left: 18px;
        margin-top: 10px;
    }

    .whiteheadLin {
        width: 24px;
        height: 2px;
        background-color: #ffffff;
        border-radius: 1px;

        margin-top: 10px;
    }

    .text16_33 {
        font-size: 16px;
        color: #333333;
    }

    .text16_66 {
        font-size: 16px;
        color: #666666;
    }

    .text16_c3 {
        font-size: 16px;
        color: #00c395;
    }

    .dynamicData {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 1600px;
        background-color: #f8f9fb;
    }

    .dynamicItem {
        display: flex;
        flex-direction: row;
        background-color: #00c395;
        width: 800px;
    }

    .stable_img {
        margin-left: auto;
        width: 240px;
        height: 160px;
        background-color: darkblue;

    }

    .box1 {
        width: 1600px;
        background: #f8f9fb;
        padding-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .arrow {
        width: 80px;
        height: 80px;
        background: url("../../views/img/right_arrow_off.png");
    }

    .arrow:hover {
        background: url("../../views/img/right_arrow_on.png");

    }

    .box1 ul {
        clear: both;
        overflow: hidden;
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    .box1 ul li {
        width: 48%;
        height: 160px;
        margin-bottom: 20px;
        float: left;
        display: flex;
        flex-direction: row;
        justify-items: center;
    }

    .stable {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

    }

    .box1 ul li:nth-child(even) {
        margin-left: 4%;
    }

    .fontBold {
        font-weight: bold
    }

    .white_125 {
        font-size: 125px;
        color: white;
        font-weight: bold;
    }

    .about-us-view {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

    }

    .about-us-data {
        margin-right: 40px;
        margin-top: 99px;
        font-size: 22px;
        color: #666666;
        line-height: 1.8;
    }

    .lin18 {
        width: 18px;
        height: 3px;
        background-color: #cccccc;
    }

    .directionRow {
        display: flex;
        flex-direction: row;
    }

    .text40 {
        color: #00c395;
        font-size: 40px;
        font-weight: bold;
    }
    .text24-22 {
        color: #222222;
        font-size: 24px;
        font-weight: bold;
    }
  .text18-55 {
        color: #555555;
        font-size: 18px;
    }

    .text30 {
        color: #00c395;
        font-size: 30px;
        font-weight: bold;
    }

    .alignItems {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .orderView {
        display: flex;
        flex-direction: row;
        width: 1600px;
        justify-content: flex-end;
    }

    .driverOrderView {
        display: flex;
        flex-direction: row;
        width: 1600px;
        margin-top: 480px;
    }

    .driver-orders {
        display: flex;
        flex-direction: column;
        margin-top: 150px;
        align-items: flex-end;
    }
    .addressPhone{
        display: flex;
        flex-direction: row;

    }
    .addressPhone-view{
        display: flex;
        flex-direction: row;

    }
    .addressView{
        display: flex;
        flex-direction: row;
        justify-content:space-between;
    }
</style>
